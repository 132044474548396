@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'swiper/css';
@import 'swiper/css/pagination';
@import '../../public/fonts/index.css';

/* styles/globals.css */
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto Flex', sans-serif;
  overscroll-behavior-y: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  outline: none;
}

* {
  box-sizing: border-box;
}

/* for the svg path fill change */
.social-btn path {
  fill: white;
}

.social-btn:hover path {
  fill: black;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  opacity: 1;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.swiper-pagination-bullet-active {
  background-color: #03d3ff;
}

.text-outline-black {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

@layer utilities {
  /* .chat-scrollbar::-webkit-scrollbar {
    width: 4px;
  } */

  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #40434a transparent;
    transition: scrollbar-color 0.3s ease;
    /* -webkit-overflow-scrolling: touch; */
    pointer-events: auto;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  /* .chat-scrollbar::-webkit-scrollbar-thumb {
    background-color: #40434a;
    border-radius: 100px;
  } */

  /* .chat-scrollbar:hover {
    padding-right: 0px;
  } */

  .spin-center {
    transform-origin: center;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

@keyframes flip {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}

.flip-animation {
  animation: flip 0.8s;
  transform-style: preserve-3d; /* Ensures the rotation happens in 3D space */
  backface-visibility: hidden; /* Hides the back face of the element during the rotation */
}

@supports (padding: max(0px)) {
  .pb-safe {
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
  }
}

.tilted-line-through {
  position: relative;
}

.tilted-line-through::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 2px solid #FF0000;
  transform: rotate(-10deg);
}

.resize-y {
  resize: vertical;
  transition: height 0.2s ease;
}

/* Character image zoom effect for specific pages */
.character-zoom-effect img {
  transition: transform 0.3s ease;
}

.character-zoom-effect:hover img {
  transform: scale(1.05);
}
