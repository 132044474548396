@font-face {
  font-family: 'Roboto Flex';
  src: url('/fonts/RobotoFlex-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Trap';
  src: url('/fonts/Trap-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Trap';
  src: url('/fonts/Trap-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Trap';
  src: url('/fonts/Trap-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Azeret Mono';
  src: url('/fonts/AzeretMono-Light.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Azeret Mono';
  src: url('/fonts/AzeretMono-Regular.ttf') format('opentype');
  font-weight: 400;
  font-style: normal;
}